import React from 'react'
import { RichText, Date } from 'prismic-reactjs'
import Slider from "react-slick" 

// Function to retrieve a small preview of the post's text
const firstParagraph = (portfolioPost => {  
  // Find the first text slice of post's body
  let firstTextSlice = portfolioPost.description;
  if (firstTextSlice != null) {
    // Set the character limit for the text we'll show in the homepage
    const textLimit = 300
    let text = RichText.asText(firstTextSlice)
    let limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      // Cut only up to the last word and attach '...' for readability
      return (
        <p>{ limitedText.substring(0, limitedText.lastIndexOf(' ')) + '...' }</p>
      );
    } else {
      // If it's shorter than the limit, just show it normally
      return <p>{ text }</p>;
    }
  } else {
    // If there are no slices of type 'text', return nothing
    return null;
  }
})

// A summary of the Blog Post
const PortfolioSummary = ({ portfolioPost }) => {
  
  // Store and format the blog post's publication date
  let postDate = Date(portfolioPost.date);
  postDate = postDate ? 
    new Intl.DateTimeFormat('en-US', {
      month: 'short', 
      day: '2-digit', 
      year: 'numeric'
    }).format(postDate) :
    '';

  // Default title when post has no title set
  const defaultTitle = "Untitled"
  
  return (
    <div className="portfolio-summary" key={ portfolioPost.id } >
      {/* Renders a small preview of the post's text */}
      <div className="embed-container" dangerouslySetInnerHTML={{
          __html: portfolioPost.embed.html
      }} />
    </div>
  );
}

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '5%',
  draggable: true,
};

export default ({ portfolioPosts }) => {
  if(!portfolioPosts) return null;
  
  return(
    <div className="portfolio-posts container" id="work">
       <Slider {...settings}>
        {portfolioPosts.map((item) => {
          return <PortfolioSummary portfolioPost={ item.portfolio_post } key={ item.portfolio_post.date }/>
        })}
      </Slider>
    </div>
  )
}
