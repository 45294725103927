import React from 'react'
import { graphql } from 'gatsby';
import htmlSerializer from '../utils/htmlSerializer'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/layouts'
import PortfolioPosts from '../components/PortfolioPosts';
import BlogPosts from '../components/BlogPosts';

// Query for the Blog Home content in Prismic
export const query = graphql`
{
  prismic {
    allHome_pages {
      edges {
        node {
          body {
            ... on PRISMIC_Home_pageBodyHero_slice {
              type
              label
              primary {
                hero_image
                subtitle
                title
              }
            }
            ... on PRISMIC_Home_pageBodyText_section {
              type
              label
              primary {
                navigation_link_title
                body1
                title
              }
            }
            ... on PRISMIC_Home_pageBodyPortfolio_section {
              type
              label
              primary {
                navigation_link_title
              }
              fields {
                portfolio_post {
                  ... on PRISMIC_Portfolio_post {
                    date
                    title
                    embed
                    description
                  }
                }
              }
            }
            ... on PRISMIC_Home_pageBodyContact_section {
              type
              label
              fields {
                social_icon
                social_link {
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
                social_title
              }
              primary {
                address
                email
                navigation_link_title
                phone
                title
              }
            }
            ... on PRISMIC_Home_pageBodyBlog_section {
              type
              label
              fields {
                blog_posts {
                  ... on PRISMIC_Post {
                    title
                    date
                    body {
                      ... on PRISMIC_PostBodyText {
                        type
                        label
                        primary {
                          text
                        }
                      }
                    }
                  }
                }
              }
              primary {
                navigation_link_title
                title
              }
            }
          }
        }
      }
    }
  }
}
`

const HeroSlice = ({ content }) => {
  const { primary } = content;
  const { hero_image, title, subtitle } = primary;
  return (
    <div className="home-header container" id="home">
      <div className="blog-avatar">
        {hero_image && (
          <div className="blog-avatar-image" style={{ backgroundImage: `url(${hero_image.url})`}}/>
        )}
        {title && (
          <div className="avatar__text">{title}</div>
        )}
        {subtitle && (
          <div className="blog-description">{subtitle}</div>
        )}
      </div>
    </div>
  )
};

const TextSlice = ({ content }) => {
  const { primary } = content;
  const { body1, navigation_link_title, title } = primary;
  const navLink = navigation_link_title ? navigation_link_title.toLowerCase().replace(/\s+/g, '-') : null;
  return (
    <div className={`container ${navLink}`} id={navLink}>
     {title && <h1>{title[0].text}</h1>}
     <RichText render={body1} htmlSerializer={htmlSerializer} />
    </div>
  )
};

const PortfolioSlice = ({ content }) => {
  const { fields, primary } = content;
  const { navigation_link_title } = primary;
  const navLink = navigation_link_title ? navigation_link_title.toLowerCase().replace(/\s+/g, '-') : null;
  return <div className="container portfolio" id={navLink}>
    <PortfolioPosts portfolioPosts={fields} />
  </div>
};

const BlogSlice = ({ content }) => {
  const { fields, primary } = content;
  const { navigation_link_title } = primary;
  const navLink = navigation_link_title ? navigation_link_title.toLowerCase().replace(/\s+/g, '-') : null;

  return <div className="container blog" id={navLink}>
    <BlogPosts posts={fields} />
  </div>;
};

const ContactSlice = ({ content }) => {
  const { primary, fields } = content;
  const { address, email, phone, title, navigation_link_title } = primary;
  const navLink = navigation_link_title ? navigation_link_title.toLowerCase().replace(/\s+/g, '-') : null;
  return (
    <div className="container contact" id={navLink}>
      {title && <h1>{title}</h1>}
      {address && <p>{address}</p>}
      {email && <p>{email}</p>}
      {phone && <p>{phone}</p>}
      {fields && fields.length && fields.map(social => <a key={social.social_title} className="social-links" href={social.social_link.url} target="_blank">{social.social_title}</a>)} 
    </div>
  );
};

const sliceComponents = {
  hero_slice: HeroSlice,
  text_section: TextSlice,
  portfolio_section: PortfolioSlice,
  contact_section: ContactSlice,
  blog_section: BlogSlice,
};

export default ({ data }) => {  
  return(
    <Layout sections={data?.prismic?.allHome_pages?.edges[0]?.node?.body}>
      {data?.prismic?.allHome_pages?.edges[0]?.node?.body.map((slice) => {
        if (!slice?.type) return null;
        const SliceComponent =
          sliceComponents[slice.type];

          if (SliceComponent) {
            return (
              <SliceComponent
                content={slice}
                key={slice.id}
              />
            );
          }

          return (
            <div key={slice.id}>
              No component has been configured for this slice: {slice.type}
            </div>
          );
      })}
    </Layout>
  )
}

